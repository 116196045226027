import React, { useEffect, useState } from "react";
import "./puan.css";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import { dataIndicatorName } from "../../utils/dummyData";
import { Button, ButtonGroup, Table } from "react-bootstrap";
import { INDICATOR } from "../../utils/urls";
import { postDataWithHeaders } from "../../utils/apiUtils";
import { useDispatch, useSelector } from "react-redux";

const objectToArrayFilters = (object) => {
  const arr = Object.keys(object).map((key) => ({
    slug: key,
    label: object[key].label,
    sort: object[key].sort,
  }));

  return arr.sort((a, b) => a.sort - b.sort);
};
const PuanComponent = (props) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.filters);
  const dataFilters = filters;
  const { urlApi, name, access_token } = props;
  const [updatedDataHacim, setUpdatedDataHacim] = useState(
    objectToArrayFilters(dataFilters.volumes)
  );
  const [updatedDataIndicators, setUpdatedDataIndicators] = useState(
    objectToArrayFilters(dataFilters.indicators)
  );
  const [updatedDataOrtalamalar, setUpdatedDataOrtalamalar] = useState(
    objectToArrayFilters(dataFilters.averages)
  );
  const updateDataAll = updatedDataHacim.concat(
    updatedDataIndicators,
    updatedDataOrtalamalar
  );

  console.log(updateDataAll);
  const [data, setData] = useState(null);
  const [dataBody, setDataBody] = useState(null);
  const [dataBody2, setDataBody2] = useState(null);
  const [dataHead, setDatahead] = useState(null);

  async function _getData(marketName) {
    let dummyHead = [];
    let dummyBody = [];
    const postData = {
      market: marketName,
      access_token: access_token,
    };
    let res = await postDataWithHeaders(INDICATOR, postData, dispatch);
    if (res.status === 200) {
      // test for status you want, etc
      console.log(res.status);
    }
    // Don't forget to return something
    if (res.code === 0) {
      console.log("data1 :", res.data);
      res.data.map(function (sIndikator, ix) {
        let dataIndicator = _.orderBy(sIndikator.indicators, ["id"], ["asc"]);
        sIndikator.indicators = dataIndicator;
        // console.log("dataIndicator: ", sIndikator);
        dummyBody.push(sIndikator);
      });
      dataIndicatorName.map(function (names, ix) {
        dummyHead.push(names);
      });
      setDatahead([dummyHead]);
      setDataBody([dummyBody]);
      setDataBody2([dummyBody]);
    } else {
      setData(null);

      setDatahead(null);
      setDataBody(null);
      setDataBody2(null);
      toast.error(res.data);
      setInterval(() => {
        //window.location.href="/uye-cikis"
      }, 2000);
    }
  }

  const handleName = (indicator) => {
    const name = _.filter(updateDataAll, { slug: indicator.slug }).map(
      (v) => v.label
    );
    console.log("slug", indicator.slug, name, updateDataAll);
    return name;
  };

  function indicatorDeger(indicator) {
    return indicator.deger;
  }

  useEffect(() => {
    _getData(name);
  }, []);

  return (
    <>
      <ToastContainer />
      <div id="container">
        <h2>{name} İndikatör Stratejisi</h2>
        <div className="Strateji-btnGroup">
          {/* <Table responsive hover>
          <thead>
            <tr>
              {dataHead &&
                dataHead[0].map((data, index) => (
                  <th key={index} className="kutu">
                    {data.adi}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody> */}
          {dataBody &&
            dataBody[0].map((data, index) =>
              data.indicators.map((indicator, index2) => {
                let btnClass = "btn-outline-secondary";
                let classInd = indicator.deger;
                {
                  console.log("classInd", indicator.slug, classInd, indicator);
                }
                if (classInd == "green") {
                  btnClass = "btn-success";
                } else if (classInd == "red") {
                  btnClass = "btn-danger";
                } else if (classInd == "red1") {
                  btnClass = "btn-danger danger1";
                } else if (classInd == "red2") {
                  btnClass = "btn-danger danger2";
                } else if (classInd == "green1") {
                  btnClass = "btn-success success1";
                } else if (classInd == "green2") {
                  btnClass = "btn-success success2";
                }
                return (
                  <Button
                    variant="default"
                    key={index2}
                    title={indicator.status}
                    className={`btn-sm rounded-pill m-1 ${btnClass}`}
                  >
                    {handleName(indicator)}
                  </Button>
                );
              })
            )}
          {/* </tbody>
        </Table> */}
        </div>
      </div>
    </>
  );
};

export default PuanComponent;
