export let favoriData = [
  {
    id: 1,
    sembol: "ACSEL",
    periyot: "15M",
    formasyon: "Kelebek",
  },
  {
    id: 2,
    sembol: "ASELS",
    periyot: "60M",
    formasyon: "Wolfe",
  },
  {
    id: 3,
    sembol: "DOHOL",
    periyot: "15M",
    formasyon: "TOBO",
  },
  {
    id: 4,
    sembol: "ACSEL",
    periyot: "15M",
    formasyon: "OBO",
  },
  {
    id: 5,
    sembol: "ACSEL",
    periyot: "15M",
    formasyon: "Yengeç",
  },
];
export let dataIndicator = [
  {
    id: 1,
    hisse: "ACSEL",
    puan: 5,
    indicators: [
      { id: 0, slug: "volume", deger: "green" },
      { id: 1, slug: "rsi", deger: "green" },
      { id: 2, slug: "wti", deger: "red" },
      { id: 4, slug: "macd", deger: "red" },
      { id: 5, slug: "bband", deger: "red" },
      { id: 6, slug: "cu", deger: "green" },
      { id: 7, slug: "qqe", deger: "green" },
      { id: 8, slug: "squeeze", deger: "green" },
      { id: 9, slug: "ao", deger: "red" },
    ],
  },
  {
    id: 2,
    hisse: "ASELS",
    puan: 3,
    indicators: [
      { id: 0, slug: "volume", deger: "red" },
      { id: 1, slug: "rsi", deger: "green" },
      { id: 2, slug: "wti", deger: "blue" },
      { id: 4, slug: "macd", deger: "red" },
      { id: 5, slug: "bband", deger: "green" },
      { id: 6, slug: "cu", deger: "red" },
      { id: 7, slug: "qqe", deger: "green" },
      { id: 8, slug: "squeeze", deger: "red" },
      { id: 9, slug: "ao", deger: "red" },
    ],
  },
  {
    id: 3,
    hisse: "DOHOL",
    puan: 8,
    indicators: [
      { id: 0, slug: "volume", deger: "green" },
      { id: 1, slug: "rsi", deger: "green" },
      { id: 2, slug: "wti", deger: "green" },
      { id: 4, slug: "macd", deger: "green" },
      { id: 5, slug: "bband", deger: "green" },
      { id: 6, slug: "cu", deger: "green" },
      { id: 7, slug: "qqe", deger: "red" },
      { id: 8, slug: "squeeze", deger: "green" },
      { id: 9, slug: "ao", deger: "red" },
    ],
  },
];
export let dataIndicatorName = [
  { id: 0, slug: "volume3", adi: "Vol / 3" },
  { id: 1, slug: "volume", adi: "Vol / 5" },
  { id: 2, slug: "rsi", adi: "RSI" },
  { id: 3, slug: "macd", adi: "MACD" },
  { id: 4, slug: "bb", adi: "BBAND" },
  { id: 5, slug: "wt_ichimoku", adi: "Wti + Ichimoku" },
  { id: 6, slug: "cm", adi: "CM Ultimate" },
  { id: 7, slug: "qqe", adi: "QQE" },
  { id: 8, slug: "squeeze", adi: "Squeeze" },
  { id: 9, slug: "pao", adi: "Awesome Oscillator" },
  { id: 10, slug: "heiken", adi: "Heikin Ashi" },
  { id: 14, slug: "heiken_1w", adi: "Heikin Ashi (H)" },
  { id: 11, slug: "ema_820", adi: "EMA 8/20 (4S)" },
  { id: 12, slug: "ema_ralli", adi: "Ralli Dizilimi" },
  { id: 13, slug: "ema_30", adi: "EMA 30 (H)" },
];

export let dataIndicatorHacim = [
  { id: 0, slug: "volume2", label: "2 Gündür Hacim Artışı Olanlar" },
  // { id: 1, slug: "volume3", label: "3 Gündür Hacim Artışı Olanlar" },
  { id: 2, slug: "volume5", label: "5 Gündür Hacim Artışı Olanlar" },
  // { id: 3, slug: "volume269", label: "Hacimde 26/9 ortlama üstünde olanlar" },
];

export let dataIndicators = [
  { id: 2, slug: "rsi", label: "RSI" },
  { id: 3, slug: "macd", label: "MACD" },
  { id: 4, slug: "bb", label: "BBAND" },
  { id: 5, slug: "wt_ichimoku", label: "Wti + Ichimoku" },
  { id: 6, slug: "cm", label: "CM Ultimate" },
  { id: 7, slug: "qqe", label: "QQE" },
  { id: 8, slug: "squeeze", label: "Squeeze" },
  { id: 9, slug: "pao", label: "Awesome Oscillator" },
  { id: 10, slug: "heiken", label: "Heikin Ashi" },
];

export let dataIndicatorOrtalamalar = [
  { id: 11, slug: "ema_820", label: "EMA 8/20 (4S)" },
  { id: 12, slug: "ema_ralli", label: "Ralli Dizilimi" },
  { id: 13, slug: "ema_30", label: "EMA 30 (H)" },
];
export let dataOscilatorName = [
  { id: 0, slug: "volume", adi: "Volume" },
  { id: 1, slug: "rsi", adi: "RSI" },
  { id: 2, slug: "wti", adi: "Wti + Ichimoku" },
  { id: 4, slug: "macd", adi: "MACD" },
  { id: 7, slug: "qqe", adi: "QQE" },
  { id: 8, slug: "squeeze", adi: "Squeeze" },
  { id: 9, slug: "ao", adi: "Awesome Oscillator" },
];
export let favoriAramaData = [
  {
    id: 1,
    adi: "Arama Adı 1",
  },
  {
    id: 2,
    adi: "Arama Adı 2",
  },
  {
    id: 3,
    adi: "Arama Adı 3",
  },
  {
    id: 4,
    adi: "Arama Adı 4",
  },
  {
    id: 5,
    adi: "Arama Adı 5",
  },
];
export let odemeData = [
  {
    id: 1,
    package: "1 Yıllık",
    start: "2022-03-01 11:49:52",
    finish: "2023-03-02 11:49:52",
  },
];

export let profilData = [
  {
    id: 1,
    username: "",
    firstname: "",
    lastname: "",
    phone: "",
    telegram_username: "",
  },
];
export const colourOptions = [
  { value: "ocean1", label: "Ocean" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
  { value: "forest", label: "Forest" },
  { value: "slate", label: "Slate" },
  { value: "silver", label: "Silver" },
];

export const dataBorsa = [
  { value: "1", label: "BIST", isDisabled: false },
  { value: "XU030", label: "XU030", isDisabled: false },
  { value: "XU050", label: "XU050", isDisabled: false },
  { value: "XU100", label: "XU100", isDisabled: false },
  { value: "CIRCLE", label: "ÇEMBER LİSTE", isDisabled: false },
  { value: "XBANK", label: "XBANK", isDisabled: false },
  { value: "XKTUM", label: "XKTUM", isDisabled: false },
  { value: "XUSIN", label: "XUSIN", isDisabled: false },
  { value: "XUTEK", label: "XUTEK", isDisabled: false },
  { value: "XILTM", label: "XILTM", isDisabled: false },
  { value: "XGIDA", label: "XGIDA", isDisabled: false },
  { value: "XTRZM", label: "XTRZM", isDisabled: false },
  { value: "XSGRT", label: "XSGRT", isDisabled: false },
  { value: "2", label: "BINANCE", isDisabled: false },
  { value: "6", label: "BINANCE FUTURES", isDisabled: false },
  { value: "3", label: "ABD BORSALARI", isDisabled: false },
  { value: "4", label: "FOREX", isDisabled: false },
  { value: "XINDEX", label: "ENDEKSLER", isDisabled: false },
  // { value: "5", label: "ENDEKSLER", isDisabled: false },
];

export const dataBorsa2 = [
  { value: "BIST", label: "BIST", isDisabled: false },
  { value: "XU100", label: "XU100", isDisabled: false },
];

export const dataBorsaFrekans = [
  { value: "1", label: "BIST", isDisabled: false },
  // { value: "2", label: "BINANCE", isDisabled: false },
  // { value: "4", label: "FOREX", isDisabled: false },
];

export const dataEndeksFrekans = [
  {
    value: "kozmik-ayi",
    label: "Kozmik Regülasyon (AYI)",
    type: 0,
    isDisabled: false,
  },
  {
    value: "kozmik-boga",
    label: "Kozmik Regülasyon (BOĞA)",
    type: 1,
    isDisabled: false,
  },
  {
    value: "proaktif-ayi",
    label: "Proaktif Nova (AYI)",
    type: 0,
    isDisabled: false,
  },
  {
    value: "proaktif-boga",
    label: "Proaktif Nova (BOĞA)",
    type: 1,
    isDisabled: false,
  },
  {
    value: "rotatif-ayi",
    label: "Rotatif Projeksiyon (AYI)",
    type: 0,
    isDisabled: false,
  },
  {
    value: "rotatif-boga",
    label: "Rotatif Projeksiyon (BOĞA)",
    type: 1,
    isDisabled: false,
  },
];

export const colors = [
  { value: "ABC=DEF", bear: "#6927FF", bull: "#6927FF" },
  { value: "GÖLGE", bear: "#6927FF", bull: "#858985" },
  { value: "BAYZ", bear: "#FF0000", bull: "#54B435" },
  { value: "BAYZ(YAKIN)", bear: "#990000", bull: "#176e16" },
];

export const dataYon = [
  { value: 0, label: "TÜMÜ", isDisabled: false },
  { value: 1, label: "BOĞA", isDisabled: false },
  { value: 2, label: "AYI", isDisabled: false },
];
export const dataYon2 = [
  { value: 0, label: "TÜMÜ", isDisabled: true },
  { value: 1, label: "BOĞA", isDisabled: false },
  { value: 2, label: "AYI", isDisabled: false },
];
export const dataYon3 = [
  { value: 1, label: "BOĞA", isDisabled: false },
  { value: 2, label: "AYI", isDisabled: false },
];
export const birliktes = [
  { value: 1, label: "Birlikte", isDisabled: false },
  { value: 2, label: "Ayrı Ayrı", isDisabled: false },
];

export const urlDevSystem = "https://api.nosce.com.tr/";
export const urlProSystem = "https://api3.siriusalgo.com/";

export const dataFormasyon = [
  { value: "1", label: "AB=CD", description: null },
  { value: "2", label: "Butterfly", description: null },
  { value: "3", label: "DeepCrab", description: null },
  { value: "4", label: "Doji", description: null },
  { value: "5", label: "Fibo618", description: null },
  { value: "6", label: "Fibo786", description: null },
  { value: "7", label: "Fibo886", description: null },
  { value: "8", label: "PU", description: null },
  { value: "9", label: "QM", description: null },
  { value: "10", label: "SimetrikUcgen", description: null },
  { value: "11", label: "SPESIFIK", description: null },
  { value: "12", label: "Tobo", description: null },
  { value: "13", label: "TOF", description: null },
  { value: "14", label: "Wolfe", description: null },
  { value: "15", label: "YukselenUcgen", description: null },
];
export const dataHarmonics = [
  { value: "abcd", label: "AB=CD", description: null },
  { value: "bat", label: "Yarasa", description: null },
  { value: "altbat", label: "Alternatif Yarasa", description: null },
  { value: "butterfly", label: "Kelebek", description: null },
  { value: "cypher", label: "Cypher", description: null },
  { value: "crab", label: "Yengeç", description: null },
  { value: "deepcrab", label: "Derin Yengeç", description: null },
  { value: "gartley", label: "Gartley", description: null },
  { value: "shark", label: "Köpekbalığı", description: null },
  { value: "5o", label: "Beş Sıfır", description: null },
];
export const dataPatterns = [
  { value: "QM", label: "QM", description: null },
  { value: "Wolfe", label: "Wolfe", description: null },
  { value: "26_18", label: "26-18", description: null },
  { value: "OBO", label: "OBO", description: null },
  { value: "TOBO", label: "TOBO", description: null },
  { value: "OMO", label: "OBOMATİK", description: null },
  { value: "FLAG", label: "BAYRAK", description: null },
  { value: "PENNANT", label: "FLAMA", description: null },
];
export const dataFibometre = [
  { value: "OLASIC", label: "OLASI C", description: null },
  { value: "YELPAZE", label: "YELPAZE", description: null },
  { value: "GUVENLI", label: "GÜVENLİ BÖLGE", description: null },
];
export const dataAll = [
  { value: "BAYZ", label: "BAYZ", bayz: 1, description: null },
  { value: "GÖLGE", label: "GÖLGE", bayz: 1, description: null },
  { value: "ABC=DEF", label: "ABC=DEF", bayz: 1, description: null },
  { value: "BAYZ(YAKIN)", label: "BAYZ(YAKIN)", bayz: 1, description: null },

  { value: "QM", label: "QM", bayz: 0, description: null },
  { value: "Wolfe", label: "Wolfe", bayz: 0, description: null },
  { value: "26_18", label: "26-18", bayz: 0, description: null },
  { value: "OBO", label: "OBO", bayz: 0, description: null },
  { value: "TOBO", label: "TOBO", bayz: 0, description: null },
  { value: "OMO", label: "OBOMATİK", bayz: 0, description: null },
  { value: "FLAG", label: "BAYRAK", bayz: 0, description: null },
  { value: "PENNANT", label: "FLAMA", bayz: 0, description: null },

  { value: "OLASIC", label: "OLASI C", bayz: 0, description: null },
  { value: "YELPAZE", label: "YELPAZE", bayz: 0, description: null },
  { value: "GUVENLI", label: "GÜVENLİ BÖLGE", bayz: 0, description: null },
];
export const dataOther = [
  { value: "QM", label: "QM", bayz: 0, description: null },
  { value: "Wolfe", label: "Wolfe", bayz: 0, description: null },
  { value: "26_18", label: "26/18", bayz: 0, description: null },
  { value: "OBO", label: "OBO", bayz: 0, description: null },
  { value: "TOBO", label: "TOBO", bayz: 0, description: null },
  { value: "OMO", label: "OBOMATİK", bayz: 0, description: null },
  { value: "FLAG", label: "BAYRAK", bayz: 0, description: null },
  { value: "PENNANT", label: "FLAMA", bayz: 0, description: null },

  { value: "OLASIC", label: "OLASI C", bayz: 0, description: null },
  { value: "YELPAZE", label: "YELPAZE", bayz: 0, description: null },
  { value: "GUVENLI", label: "GÜVENLİ BÖLGE", bayz: 0, description: null },
];
export const dataBayz = [
  {
    value: "BAYZ",
    label: "BAYZ",
    bayz: 1,
    description: null,
    isDisabled: false,
  },
  {
    value: "GÖLGE",
    label: "GÖLGE",
    bayz: 1,
    description: null,
    isDisabled: false,
  },
  {
    value: "ABC=DEF",
    label: "ABC=DEF",
    bayz: 1,
    description: null,
    isDisabled: false,
  },
  {
    value: "BAYZ(YAKIN)",
    label: "BAYZ(YAKIN)",
    bayz: 1,
    description: null,
    isDisabled: false,
  },
];
export const stratejiAdet = 14;
